import { createRoot, createComputed, onCleanup } from "solid-js";
import { unwrap } from "solid-js/store";
import type { StateAsyncValue } from "./create-async";
import type { Owner } from "solid-js";

export type StateEventsAsyncStatus = "first-assign" | "reassign" | "cleared" | "errored";
export type StateEventsAsyncProps<T> = { value: T; previous: T; error: any; numAssigned: number };
export type StateEventsAsyncFunc<T> = (event: StateEventsAsyncStatus, props: StateEventsAsyncProps<T>) => void;
export type StateEventsAsyncInstance<T> = ReturnType<typeof createEventsAsync<T>>;

function eventOccured<T>(
  obj: StateEventsAsyncProps<T>,
  value: T,
  previous: T,
  state: StateAsyncValue<any>["state"],
  error: any
) {
  let event: StateEventsAsyncStatus = undefined;
  if (!state.loading) {
    if (error) {
      event = "errored";
    } else {
      if (obj.numAssigned === 0) {
        if (value !== undefined && value !== null) {
          event = "first-assign";
        }
      } else {
        if (!state.refetch) {
          if (previous !== value) {
            event = value === undefined || value === null ? "cleared" : "reassign";
          }
        }
      }
    }
  }
  if (event) {
    obj.value = value;
    obj.previous = previous;
    obj.error = event === "errored" ? error : undefined;
    obj.numAssigned++;
  }
  return event;
}

export function createEventsAsync<T>(state: StateAsyncValue<any>, owner?: Owner) {
  const obj: StateEventsAsyncProps<T> = {
    value: undefined,
    previous: undefined,
    error: undefined,
    numAssigned: 0,
  };
  let funcs: StateEventsAsyncFunc<T>[] = undefined;
  let id = 0;
  let previous = unwrap(state.value);
  createRoot(() => {
    createComputed(() => {
      const l = state;
      const v = unwrap(state.value);
      const s = unwrap(state.state);
      const e = unwrap(state.error);
      // console.log("updated async state :: ", state.state);
      if (!funcs || funcs.length < 0) return;
      const event = eventOccured(obj, v, previous, s, e);
      previous = v;
      if (event) {
        funcs.forEach((func) => {
          func?.(event, obj);
        });
      }
    });
    onCleanup(() => {
      (funcs = undefined), (id = 0);
    });
  }, owner);
  // if (owner === undefined || owner === null) {

  // } else {
  //   runWithOwner(owner, () => {
  //     createEffect(
  //       on(
  //         () => ({ v: state.value, s: state.state }),
  //         (value, previous) => {
  //           if (!funcs || funcs.length < 0) return;
  //           const event = eventOccured(obj, value.v, previous, state);
  //           if (event) {
  //             funcs.forEach((func) => {
  //               func?.(event, obj);
  //             });
  //           }
  //         }
  //       )
  //     );
  //     onCleanup(() => {
  //       (funcs = undefined), (id = 0);
  //     });
  //   });
  // }
  return (set_func: StateEventsAsyncFunc<T>) => {
    id++;
    (set_func as any).__event_id = id;
    if (!funcs) funcs = [];
    funcs.push(set_func);
    return {
      unsubscribe() {
        const event_id = (set_func as any).__event_id;
        const event_idx = funcs.indexOf(set_func);
        // console.log("unsubscribing from event ", event_id, " :: ", event_idx);
        if (event_idx < 0) {
          console.error(
            "create-events: fatal couldn't find reference to registered event ",
            event_id,
            " :: ",
            set_func
          );
          return;
        }
        funcs.splice(event_idx, 1);
        this.unsubscribe = () => {
          console.warn("create-events: this event has already unsubscribed ", set_func);
        };
      },
    };
  };
}
